@font-face {
    font-family: 'Neue Haas';
    src: url('./assets/fonts/haas/neuehaasunica-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/haas/neuehaasunica-bold-webfont.woff') format('woff'),
    url('./assets/fonts/haas/neuehaasunica-bold-webfont.svg#neue_haas_unicabold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas';
    src: url('./assets/fonts/haas/neuehaasunica-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/haas/neuehaasunica-light-webfont.woff') format('woff'),
    url('./assets/fonts/haas/neuehaasunica-light-webfont.svg#neue_haas_unicalight') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas';
    src: url('./assets/fonts/haas/neuehaasunica-medium-webfont.woff2') format('woff2'),
    url('./assets/fonts/haas/neuehaasunica-medium-webfont.woff') format('woff'),
    url('./assets/fonts/haas/neuehaasunica-medium-webfont.svg#neue_haas_unicamedium') format('svg');
    font-weight: 200;
    font-style: normal;

}

@font-face {
    font-family: 'Neue Haas';
    src: url('./assets/fonts/haas/neuehaasunica-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/haas/neuehaasunica-regular-webfont.woff') format('woff'),
    url('./assets/fonts/haas/neuehaasunica-regular-webfont.svg#neue_haas_unicaregular') format('svg');
    font-weight: 300;
    font-style: normal;

}

html {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: 'Neue Haas', serif;
    font-size: 10px;
    background-color: #2440CD;
    text-align: center;
    color: white;
    max-width: 100%;
    overflow-x: hidden;
}

.bold {
    font-weight: 400;
}

ul {
    margin-block: 0;
}

li {
    text-align: left;
    list-style-type: "★";
    padding: 0 1vw 1vh;
}

p {
    margin: 0;
}

a {
    color: #2440CD
}

a:visited {
    color: #2440CD
}
