.header-logo {
    margin: 7.5vh 0 0;
    text-align: center;
    height: 7.5vh;

    .logo {
        height: 100%;
    }
}

.headline {
    padding: 10vh 0 0;
}

.intro-image {
    background: url(./assets/images/ballsport_mobile.svg) no-repeat center;
    background-size: contain;
    margin: 0 0 2vh;
    position: relative;
    height: 50vh;
}

.book-now-one {
    position: absolute;
    right: -25%;
    top: -18%;
    background: url("./assets/images/jetzt_buchen_stern.svg") no-repeat center;
    background-size: contain;
    height: 15vh;
    width: 80%;
}

.intro-text {
    width: 90vw;
    margin: 5vh auto 0;
    line-height: 1.3em;
    font-size: 2.2em;
    font-weight: 100;
}

.section {
    padding: 5vh 0 10.5vh;
    position: relative;
}

.break {
    height: 2vh;
    width: 90%;
    background: url("./assets/images/zick_zack_desktop.svg") no-repeat center;
    margin: 5vh auto;
}

.book-now-gif {
    width: 50%;
    z-index: 6;
}

.kachel-container {
    position: relative;
}

.eroeffnung1 {
    background: url("./assets/images/bereit.svg") no-repeat center;
    height: 75vw;
}

.eroeffnung2 {
    background: url("./assets/images/sommer25.svg") no-repeat;
    height: 80vw;
    width: 50vw;
}

.kachel {
    background-size: contain;
    margin: 0 auto;
}

.hand {
    position: absolute;
    width: 45vw;
}

.links {
    left: -10vw;
    bottom: -5vh;
}

.rechts {
    transform: scaleX(-1) rotate(-15deg);
    top: 18vh;
    right: -7vw;
}

.visual {
    max-width: 90vw;
    padding: 0 0 2vh;
}

.title {
    background-size: contain;
    margin: 0 auto 5vh;
    height: 10vh;
    max-width: 90vw;
}

.about-title {
    background: url(./assets/images/aussenplaetze.svg) no-repeat center;
}

.news-title {
    background: url(./assets/images/aktuelles.svg) no-repeat center;
}

.family-title {
    background: url(./assets/images/wirsindelpadel.svg) no-repeat center;
}

.text {
    font-size: 2vh;
    font-weight: 100;
    max-width: 90vw;
    margin: 0 auto;
}

.news {
    max-width: 90vw;
    margin: 0 auto;

    a {
        color: white;
        font-weight: 300;
    }
}

.news-box {
    padding: 0 0 4vh;
}

.who-text {
    padding: 0 0 4vh;
}

.image {
    max-width: 90vw;
    margin: 0 auto;
    padding: 0 0 2vh;
}

.que-es {
    width: 80vw;
    margin: 0 auto;
    padding: 0 0 4vh;
}

.footer-container {
    min-height: 50vh;
    font-size: 2.5vh;
    text-align: center;
    background-color: white;
    color: #2440CD;
    font-weight: 180;
    padding: 10vh 0;
}

.contact-container {
    padding: 5vh 0 0;
}

@media screen and (min-width: 1100px) {
    .intro-image {
        background: url(./assets/images/ballsport_desktop.svg) no-repeat center;
        background-size: contain;
        margin: 0 0 5vh;
    }

    .headline {
        padding: 7vh 0 0;
    }

    .book-now-one {
        top: 0;
        right: 15%;
        height: 220px;
        width: 220px;
    }

    .intro-text {
        width: 40vw;
        font-size: 3.5em;
    }

    .intro2 {
        transform: translateX(10vw);
    }

    .break {
        transform: scaleX(150%);
    }

    .book-now-gif {
        width: 15%;
    }

    .kacheln {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: center;
        max-height: 50vh;
    }

    .kachel-container {
        padding: 0 20px;
    }

    .eroeffnung1 {
        width: 25vw;
        height: 25vw;
    }

    .eroeffnung2 {
        width: 25vh;
    }

    .hand {
        width: 15vw;
    }

    .rechts {
        transform: scaleX(-1) rotate(65deg);
        top: 5vh;
        right: -17vw;
    }

    .info-list, .news-boxes {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        ul, .news-box {
            flex-basis: 40%;
        }
    }

    .news-box {

        p {
            max-width: 50%;
            margin: 0 auto;
        }
    }

    .who-text {
        max-width: 60vw;
    }

    .image {
        max-width: 60vw;
    }

    .family-picture {
        transform: translateX(-5vw);
    }

    .group-picture {
        transform: translateX(5vw);
    }

    .que-es {
        width: 40vw;
    }

    .que-es-text {
        width: 55vw;
    }

    .footer-container {
        text-align: left;
        padding: 10vh 0 10vh 10vw;
    }

    .footer-links {
        display: inline-block;
        margin-right: 20vw;
    }

    .contact-container {
        display: inline-block;
        vertical-align: top;
        padding: 0;
    }
}